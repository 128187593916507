(<template>
  <div class="job-id__cell-wrapper">
    <span class="job-id__number"
          @click="onIdClick">{{ jobId }}</span>
    <div class="job-id__godmode-links">
      <button v-if="godmodeDemanderUrl"
              class="sk-link sk-link--default godmode-link"
              @click="openGodmodeLink(godmodeDemanderUrl)">{{ $gettext('[K]') }}</button>
      <button v-if="godmodeSupplierUrl"
              class="sk-link sk-link--default godmode-link"
              @click="openGodmodeLink(godmodeSupplierUrl)">{{ $gettext('[T]') }}</button>
    </div>
  </div>
</template>)

<script>
  export default {
    computed: {
      jobId() { return this.params.data?.id; },
      godmodeSupplierUrl() { return this.params.data?.godmodeSupplierUrl; },
      godmodeDemanderUrl() { return this.params.data?.godmodeDemanderUrl; }
    },
    methods: {
      openGodmodeLink(link) {
        window.open(link, '_blank');
      },
      onIdClick() {}
    }
  };
</script>
<style scoped>
.job-id__cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.job-id__number {
  margin-left: 5px;
  color: var(--color-primary);
  font-weight: 400;
}

.job-id__godmode-links {
  margin-left: 10px;
}

.godmode-link {
  font-weight: bold;
}

.godmode-link:not(:first-child) {
  margin-left: 5px;
}
</style>
