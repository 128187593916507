<template>
  <section class="text-filter">
    <sk-input :placeholder="placeholder"
              :preselected-value="searchKey"
              @fieldvaluechanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        searchKey: '',
        requestTimer: ''
      };
    },
    computed: {
      placeholder() { return this.$gettext('Name, email, phone'); },
    },
    methods: {
      onInputBoxChanged(value) {
        if (value !== this.searchKey) {
          clearInterval(this.requestTimer);
          this.searchKey = value;
          this.requestTimer = setTimeout(() => {
            this.params.parentFilterInstance((instance) => {
              instance.onFloatingFilterChanged('contains', value);
            });
          }, 500);
        }
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.searchKey = '';
        } else {
          this.searchKey = parentModel.filter;
        }
      }
    }
  };
</script>

<style>
.text-filter .sk-input {
  margin-bottom: 0;
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.text-filter {
  width: 100%;
  margin: auto;
}
</style>
