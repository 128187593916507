import helpers from '@/helpers';

export default {
  data() {
    return {
      currentPage: Number(this.$route.query.page) || 1,
      items: this.$store.state.filterItemsAmount || 10,
      ransackParams: {},
      fetchGridDataAction: '',
      // ------- Tabs Data ------- //
      tabForUpdate: '',
      gridOptions: {
        pagination: true,
        suppressPaginationPanel: true,
        suppressMovableColumns: true,
        suppressColumnVirtualisation: false,
        paginationPageSize: this.$store.state.filterItemsAmount,
        paginationAutoPageSize: false,
        suppressExcelExport: true,
        suppressRowClickSelection: true,
        rowHeight: 65,
        popupParent: null,
        rowStyle: {'max-height': '58px'},
        rowModelType: 'infinite',
        // ------ cacheBlockSize Equal to pageSize no caching ------- //
        cacheBlockSize: this.$store.state.filterItemsAmount,
        maxBlocksInCache: 1,
        maxConcurrentDatasourceRequests: 2,
        domLayout: 'autoHeight',
        floatingFiltersHeight: 60
      }
    };
  },
    computed: {
      multiSelectFilterParams() {
        return {
          filter: true,
          suppressMenu: true,
          floatingFilterComponentParams: {suppressFilterButton: true},
          filterParams: {
            filterOptions: [
              {
                displayKey: 'inList',
                displayName: 'In List'
              }
            ]
          },
        };
      },
      textFilterNoMenu() {
        return {
          filter: true,
          suppressMenu: true,
          floatingFilterComponentParams: {suppressFilterButton: true}
        };
      },
      dateFilterParams() {
        return {
          suppressAndOrCondition: true,
          defaultJoinOperator: 'AND',
          buttons: ['reset', 'apply'],
          filterOptions: ['inRange']
        };
      },
      numberFilterParams() {
        return {
          suppressAndOrCondition: true,
          defaultJoinOperator: 'AND',
          buttons: ['reset', 'apply'],
          filterOptions: ['equals', 'inRange', 'lessThan', 'greaterThan']
        };
      }
    },
    methods: {
      fetchDataFunc(params) {
        if (!params.firstTimeGridLoad) {
            const ransackParams = {
              ...params.ransackParams
            };
            for (const camelCaseKey in params.filterModel) {
              const filterType = params.filterModel[camelCaseKey].filterType;
              let ransackParam = '';
              // --- To snake case --- //
              const snakeCaseField = helpers.formatParams.camelToSnakeCase(camelCaseKey);
              // --- Map predicaments --- //
              const type = params.filterModel[camelCaseKey].type;
              const pred = helpers.formatParams.mapToRansackPredicates(type);
              // --- Create the new param --- //
              ransackParam = `s[${snakeCaseField + pred}]`;
              // ---------- Customer type Filter ----------- //
              if (camelCaseKey === 'customerType' && type === 'inList') {
                const selectedCustomerTypes = params.filterModel[camelCaseKey].filter.split(',');

                for (const selectedCustomerType of selectedCustomerTypes) {
                  switch (selectedCustomerType) {
                    case 'enterpriseCustomerType':
                      ransackParams['s[enterprise_membership_id_not_null]'] = 1;
                      break;
                    case 'privateCustomerType':
                      ransackParams['s[buyer_id_not_null]'] = 1;
                      ransackParams['s[enterprise_membership_id_null]'] = 1;
                      break;
                    case 'undefinedCustomerType':
                      ransackParams['s[buyer_id_null]'] = 1;
                      ransackParams['s[enterprise_membership_id_null]'] = 1;
                      break;
                  }
                }
                // ---------- Supplier type Filter ----------- //
              } else if (camelCaseKey === 'supplierType') {
                const selectedSupplierTypes = params.filterModel[camelCaseKey].filter.split(',');

                for (const selectedSupplierType of selectedSupplierTypes) {
                  switch (selectedSupplierType) {
                    case 'interpreterSupplierType':
                      ransackParams['s[interpreter_id_not_null]'] = 1;
                      break;
                    case 'translatorSupplierType':
                      ransackParams['s[seller_id_not_null]'] = 1;
                      break;
                    case 'bothSupplierType':
                      ransackParams['s[interpreter_id_not_null]'] = 1;
                      ransackParams['s[seller_id_not_null]'] = 1;
                      break;
                  }
                }
                // ---------- Hints Filter ----------- //
              } else if (camelCaseKey === 'hintsFilter') {
                const filter = params.filterModel[camelCaseKey].filter;
                // schema filterName--value
                const filterArray = filter.split('--');
                const hintFilterType = filterArray[0];
                const hintFilterValue = filterArray[1];
                  switch (hintFilterType) {
                    // ------- Job Hint Filters ------- //
                    case 'standBy':
                      ransackParams['s[standby_processing_eq]'] = hintFilterValue;
                      break;
                    case 'sessionType':
                      ransackParams['s[session_type_eq]'] = hintFilterValue;
                      break;
                    case 'autoAward':
                      ransackParams['s[allow_auto_award_eq]'] = hintFilterValue;
                      break;
                    case 'invitationStatus':
                      ransackParams['s[check_invite_status_eq]'] = hintFilterValue;
                      break;
                    case 'genderType':
                      ransackParams['s[gender_eq]'] = hintFilterValue;
                      break;
                    case 'confirmationBy':
                      ransackParams['s[confirmation_by_not_null]'] = hintFilterValue;
                      break;
                    case 'alternativeLanguage':
                      ransackParams['s[alternative_language_pair_id_not_null]'] = hintFilterValue;
                      break;
                    case 'qualification':
                      ransackParams['s[specific_qualification_requested_eq]'] = hintFilterValue;
                      break;
                    case 'interpreterWithdrew':
                      ransackParams['s[had_withdrawal_eq]'] = hintFilterValue;
                      break;
                    case 'directJob':
                      ransackParams['s[directly_invited_interpreter_uid_not_null]'] = hintFilterValue;
                      break;
                  }
                // ---------- Order Hints Filter ----------- //
              } else if (camelCaseKey === 'projectHints') {
                const filter = params.filterModel[camelCaseKey].filter;
                // schema filterName--value
                const filterArray = filter.split('--');
                const hintFilterType = filterArray[0];
                const hintFilterValue = filterArray[1];
                switch (hintFilterType) {
                  case 'accessLevel':
                    ransackParams['s[access_level_eq]'] = hintFilterValue;
                    break;
                  case 'initialFilesAttached':
                    ransackParams['s[initial_files_attached_eq]'] = hintFilterValue;
                    break;
                  case 'orderSendByPost':
                    switch (hintFilterValue) {
                      case 'approved':
                        ransackParams['s[delivery_method_eq]'] = 1;
                        ransackParams['s[send_by_post_status_eq]'] = 1;
                        ransackParams['s[sent_by_post_eq]'] = false;
                        break;
                      case 'sent':
                        ransackParams['s[delivery_method_eq]'] = 1;
                        ransackParams['s[sent_by_post_eq]'] = true;
                        break;
                      case 'pending':
                        ransackParams['s[delivery_method_eq]'] = 1;
                        ransackParams['s[send_by_post_status_eq]'] = 0;
                        ransackParams['s[sent_by_post_eq]'] = false;
                        break;
                      case 'rejected':
                        ransackParams['s[delivery_method_eq]'] = 1;
                        ransackParams['s[send_by_post_status_eq]'] = 2;
                        ransackParams['s[sent_by_post_eq]'] = false;
                        break;
                      case 'emailDelivery':
                        ransackParams['s[delivery_method_eq]'] = 0;
                        break;
                    }
                    break;
                  case 'additionalCCEmail':
                    ransackParams[`s[cc_emails_length_${hintFilterValue}]`] = 0;
                    break;
                }
                // ---------- Subtask Hints Filter ----------- //
              } else if (camelCaseKey === 'subtaskHints') {
                const filter = params.filterModel[camelCaseKey].filter;
                // schema filterName--value
                const filterArray = filter.split('--');
                const hintFilterType = filterArray[0];
                const hintFilterValue = filterArray[1];
                switch (hintFilterType) {
                  case 'subtaskInvitationStatus':
                    if (hintFilterValue === 'disabled') ransackParams['s[seller_id_null]'] = true;
                    else ransackParams['s[subtask_status_eq]'] = hintFilterValue;
                    break;
                  case 'wipAttachmentPresent':
                    ransackParams['s[wip_files_attached_eq]'] = hintFilterValue;
                    break;
                  case 'finalFilesAttached':
                    ransackParams['s[final_files_attached_eq]'] = hintFilterValue;
                    break;
                  case 'subtaskDangerLevel':
                    ransackParams[`s[danger_level_${hintFilterValue}]`] = true;
                    break;
                }
                // ---------- Category Range Filter ----------- //
              } else if (camelCaseKey === 'unFilteredCategoryItemId' || camelCaseKey === 'filteredCategoryItemId') {
                ransackParams['s[category_item_id_eq]'] = params.filterModel[camelCaseKey].filter;
                // ---------- Number Range ----------- //
              } else if (type === 'inRange' && filterType === 'number') {
                ransackParams[`s[${snakeCaseField}_gteq]`] = params.filterModel[camelCaseKey].filter;
                ransackParams[`s[${snakeCaseField}_lteq]`] = params.filterModel[camelCaseKey].filterTo;
                // ---------- Date Range Filter ----------- //
              } else if (type === 'inRange') {
                ransackParams[`s[${snakeCaseField}_gteq]`] = this.$moment(params.filterModel[camelCaseKey].dateFrom);
                ransackParams[`s[${snakeCaseField}_lteq]`] = this.$moment(params.filterModel[camelCaseKey].dateTo);
                // ---------- Date Filter ----------- //
              } else if (type !== 'inRange' && filterType === 'date') {
                ransackParams[ransackParam] = this.$moment(params.filterModel[camelCaseKey].dateFrom);
                // ---------- Multiselect Filter ----------- //
              } else if (type === 'inList') {
                ransackParams[ransackParam] = params.filterModel[camelCaseKey].filter.split(',');
              } else if (type === 'blank' || type === 'notBlank') {
                ransackParams[ransackParam] = type === 'blank';
              } else {
                ransackParams[ransackParam] = params.filterModel[camelCaseKey].filter;
              }
            }
            this.currentPage = params.page;
            this.items = this.$store.state.filterItemsAmount;

            const tableParams = {
              page: params.page,
              items: this.items,
              ...ransackParams
            };

          const filterParams = params.filterModel;
          let filterQuery = '';
          for (const filterParamsKey in filterParams) {
            filterQuery = `${filterQuery}&${filterParamsKey}=${filterParams[filterParamsKey].filter}`;
          }

          // basic grid tab and pagination filters
          let queries = this.currentView
            ? 'view=' + this.currentView + '&'
            + 'page=' + this.currentPage + '&'
            + 'items=' + this.items
              : 'page=' + this.currentPage + '&'
              + 'items=' + this.items;

          // grid filters queries
          if (filterQuery) queries += filterQuery;

          // eslint-disable-next-line no-restricted-globals
          history.replaceState(
            {},
            null,
            this.$route.path + '?'
            + queries
          );

            // -----Sorting Params----//
            if (params.sortModel && params.sortModel.length) {
              const sortField = helpers.formatParams.camelToSnakeCase(params.sortModel[0]?.colId);
              const sortType = params.sortModel[0].sort;
              tableParams['s[s]'] = sortField + ' ' + sortType;
            }

            return this.$store.dispatch(this.fetchGridDataAction, tableParams);
        } else {
        return Promise.resolve({
          data: [{}],
          count: 1000000,
          page: 1,
          pages: 1,
          mockToAvoidGridFirstRequest: true
        });
      }
      },
      numberFilterParam() {
        return {
          allowedCharPattern: '\\d\\-\\,\\$',
          suppressAndOrCondition: true,
          numberParser: (text) => {
            return text == null
              ? null
              : parseFloat(text.replace(',', '.').replace('$', ''));
          },
        };
      },
      dateFormatter(params) {
        return params?.value ? this.$moment(params.value).format('DD.MM.YYYY, HH:mm') : '';
      },
      setCurrentView(view, ransackParams) {
        this.currentView = view;
        this.ransackParams = ransackParams;
        this.updateGridData();
      },
      getChildInterface(childInterface) {
        this.$options.childInterface = childInterface;
      },
      updateGridData() {
        this.$options.childInterface.updateData({ransackParams: this.tabRansackParams, resetFilters: true});
      }
    }
  };
